<template>
  <div>
    <!-- select 2 demo -->
    <b-modal id="modal-report" centered ok-only size="sm" hide-footer="true" title="Report">
      <h4>Uploaded by</h4>
      <b-row style="margin: 5px 0px; align-items: center;">
        <b-avatar :src="userData.avatar" size="48" class="mr-1" />
        <div>
          <h6>{{ userData.name }}</h6>
          <h6>{{ userData.role }}</h6>
        </div>
      </b-row>
      <br />
      <h4>Reported by</h4>
      <b-row v-for="data in reportObj" :key="data.id" style="margin: 5px 0px; align-items: center;">
        <b-avatar :src="data.avatar" size="46" class="mr-1" />
        <div>
          <h6>{{ data.name }}</h6>
          <h6>{{ data.role }}</h6>
          <h6><b>Reason:</b> {{ data.issue }}</h6>
        </div>
      </b-row>
    </b-modal>

    <b-card no-body class="mb-0 ">
      <div class="m-2">
        <!-- Table Top -->

        <b-row align-h="end" style="align-items: center ;">
          <b-col class="" cols="*" lg="*" md="*" sm="*">
            <b-button variant="warning" @click="LoadReels()" style="margin-left: 10px;">
              <span class="text-nowrap">
                Reels
                <b-badge variant="danger" class="badge-glow" v-if="allCount != 0">
                  {{ allCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="info" @click="LoadPosts()" style="margin: 0px 10px;">
              <span class="text-nowrap">
                Posts
                <b-badge variant="danger" class="badge-glow" v-if="pendingCount != 0">
                  {{ pendingCount }}
                </b-badge></span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table :items="items" :fields="computedFields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(cover)="data">
            <b-img v-if="isImage(data.item.post.cover)" :src="data.item.post.cover"
              style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
            <video v-else-if="isVideo(data.item.post.cover)" type="video" style="width: 100px; height: 100px;" controls
              autoplay muted>
              <source :src="data.item.post.cover" type="video/mp4" />
            </video>
          </template>

          <template #cell(reels)="data">
            <video type="video" style="width: 100px; height: 100px;" controls autoplay muted>
              <source :src="data.item.reel.reelURL" type="video/mp4" />
            </video>
          </template>

          <template #cell(caption)="data">
            {{ data.item.post.caption }}
          </template>
          <template #cell(captionReel)="data">
            {{ data.item.reel.caption }}
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Report" size="16" @click="View(data.item)">
                <feather-icon size="16" icon="EyeIcon" />
              </b-button>
            </template>
            <template>
              <b-button v-if="loadingReels" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger"
                class="ml-1 btn-icon" v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete Reel" size="16"
                @click="DeleteReel(data.item.reel.id)">
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>

              <b-button v-else v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete Post" size="16"
                @click="DeletePost(data.item.post.id)">
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </template>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BEmbed,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    //Multiselect,
    BEmbed,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed: {
    computedFields() {
      if (this.loadingReels) return this.fieldsReels;
      else if (this.loadingPost) return this.fieldsPost;
      else return [];
    },
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      loadingReels: false,
      loadingPost: false,
      reportObj: {},
      fileProfile: "",
      logoloading: false,
      cover: "",
      coverProfile: "",
      coverLoading: false,
      moreloading: false,
      morefile: null,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fieldsPost: [
        "#",
        { label: "post", key: "cover" },
        { label: "caption", key: "caption" },
        { label: "reported Count", key: "reportedCount" },
        "actions",
      ],
      fieldsReels: [
        "#",
        { label: "reel", key: "reels" },
        { label: "caption", key: "captionReel" },
        { label: "reported Count", key: "reportedCount" },

        "actions",
      ],
      filterStatus: "",
      items: [],
      request: false,
      myObj: {
        id: 0,
        profilePic: "",
        name: "",
        title: "",
        cover: "",
        details: "",
        date: "",
        time: "",
        postedOn: "",
        status: "active",
        moreImages: "",
      },
      searchQuery: "",
      isUpdating: false,
      moreImg: [],
      userData: {},
    };
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    View(item) {
      this.reportObj = item.reportedBy;
      this.userData = item.userData;
      this.$bvModal.show("modal-report");
    },
    DeleteReel(id) {
      console.log(id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Reels/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                this.$bvToast.toast("Reel deleted successfully!.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.LoadReels();
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DeletePost(id) {
      console.log(id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Posts/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.$bvToast.toast("Post deleted successfully!.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.LoadPosts();
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadPosts() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/ReportedPosts/LoadReportedPosts",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.loadingPost = true;
          this.loadingReels = false;
          console.log(response);
          this.items = [];
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadReels() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/ReportedPosts/LoadReportedReels",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.loadingReels = true;
          this.loadingPost = false;
          console.log(response);
          this.items = [];
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .moreImg {
  flex: 0 0 auto;
  position: relative;
}
</style>
